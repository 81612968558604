.pageWrapper{
  min-height: 100vh;
  padding-bottom: 30px;
}

.profilePicture {
    margin-top: 10px;
    height: 130px;
    border-radius: 50%;
    margin: auto;    
    display: block;
}

.subHeaderStyle {
  text-align: center;
}

.cardArt {
    height: 100px;
}

.title {
    font-size: 3.0rem;
    padding-bottom: 0;
}

.linkButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
  }

  .anchorColor {
    color: white;
  }

  .anchorColor:hover {
    text-decoration: underline;
    color: white;
  }

  .card {
    background-color: rgba(189, 189, 200, 0.2);
    border: none;
    backdrop-filter: blur(6px);
    width: 100%;
    padding-top: 1.0em;
    padding-right: 1em;
    padding-bottom: 1.5em;
    padding-left: 1em;
    border-radius: 5px;
  }
  
  .cardFont, button, h2, h3, h4 {
    font-family: 'Jost', sans-serif;
    margin-bottom: 5px;
    margin-top: 5px;
    color: white;
  }

  .nameHeaderStyle {
    font-family: 'Jost', sans-serif;
    margin-bottom: 5px;
    margin-top: 5px;
    color: white;  
    text-align: center;
  }

  .modalFont {
    font-family: 'Jost', sans-serif;
    margin-bottom: 5px;
    color: black;
  }

  .pad {
    padding: 5px;
  }

  .pad2 {
      padding: 20px;
  }

  .pad-2-bottom {
    padding-bottom: 20px;
  }

  .margin-1-bottom {
      margin-bottom: 10px;
  }

  .margin-1-top {
    margin-top: 10px;
  }

  .margin-right {
      margin-right: 5px;
  }

  .margin-left {
    margin-left: 5px;
}

  .customContainer {
      display: flex;
      justify-content: center;
  }

  .button {
    color: #212529;
    border-color: #212529;
    margin-bottom: 0.3em;
  }